.account {
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  &.account--photo {
    background-size: cover;

    .account__card {
      color: #ffffff;
    }

    .form__form-group-icon, .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon, input:not(:last-child) {
      border-right: none;
    }

    .form__form-group-button, input {
      border-left: none;
    }

    .form__form-group-button.active {
      border-color: #eff1f5;

      svg {
        fill: $color-accent;
      }
    }

    input {
      color: #ffffff;

      &:focus {
        border-color: #eff1f5;
      }
    }

    p {
      color: #ffffff;
    }
  }
}

.account__title {
  margin-top: 15px;
}

.account__wrapper {
  margin: auto;
  padding: 10px;
}

.account__card {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  background-color: #ffffff;
  padding: 50px 60px;
  max-width: 520px;
  width: 100%;
  border-radius: 3px
}

.account__profile {
  text-align: center;
}

.account__btns {
  display: flex;
  width: calc(100% + 10px);
  margin: -10px 0 -20px -10px;

  a {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
  }
}

.account__btn {
  width: 100%;
  margin-right: 0;
}

.account__login__btn {
  margin-top: 30px;
}

.account__avatar {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
}

.account__name {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 15px;
  margin-top: 5px;
}

.account__sub {
  margin-top: 0;
  margin-bottom: 10px;
  color: $color-additional;
  font-size: 11px;
  line-height: 15px;
}

.account__forgot-password {
  position: absolute;
  font-size: 11px;
  line-height: 15px;
  bottom: -18px;
  right: 0;

  a {
    color: $color-accent;

    &:hover {
      color: $color-accent-hover;
      text-decoration: none;
    }
  }
}

.form__form-group--forgot {
  margin-bottom: 40px;
}

.account__head {
  margin-bottom: 30px;
  padding-left: 10px;
}

@media screen and (max-width: 520px) {

  .account__card {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 425px) {

  .account__btns {
    flex-wrap: wrap;
  }
}

.login__logo {
  width: 300px;
  height: 64px;
  margin: auto 0;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
  display: block;

  @include themify($themes) {
    background-image: themed('logoImg');
  }
}

.login__error {
  color: $color-red;
  padding-top: 10px;
}

.login__success{
  color: $color-green;
  padding-top: 10px;
  padding-bottom: 20px;
}

.account__form-text {
  @include themify($themes) {
    color: themed('colorTextAdditional');
  }
}
